import SagaHeroCard from '../components/Sagasys_Box/hero_box'
import OPAHeroImage from '../static/images/OPA_img.svg'
import Box from '@mui/material/Box';

import Grid from '@mui/material/Grid';


export default function Home() {
  return (

    <Box sx={{ flexGrow: 1 }}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '90vh' }} >


          <Grid item xs={7} >
            <SagaHeroCard image={OPAHeroImage} />
          </Grid>


      </Grid>
    </Box>


  );
}