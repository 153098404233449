import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ChatIcon from '../../static/images/solid-chat-alt.svg'
import ArrowIcon from '../../static/images/right-arrow-icon.svg'


export default function HeroBox(props) {
    return (

        <Grid
            sx={{ p: 2, flexGrow: 1 }}
            container direction="row"
            justifyContent="center"
            alignItems="center"
        >
            <Grid item xs={6} sx={{ display: { xs: 'none', md: 'block' },  }} >
                <Typography variant="h2" style={{letterSpacing: '0.04em' }} sx={{ fontFamily: 'Goldman', color: "#F9FAFB", textTransform: "uppercase", fontWeight: '700', fontSize: '48px',}}>
                    Specialized in eligibility automation
                </Typography>
                <Typography variant="h6" sx={{ color: "#E5E7EB", fontWeight: '400', fontSize: '24px', lineHeight: '32px', marginTop: '12px' }}>
                    Building custom solutions using <a href="https://www.oracle.com/cx/service/intelligent-advisor/" style={{ color: "#FB7908" }}>Oracle's Intelligent Advisor</a> platform.
                </Typography>
                <Grid container columns={3} alignContent="left" sx={{ marginTop: '24px' }}>
                    <Grid item xs={1} sx={{ marginRight: '48px', minWidth: "220px" }}>
                        <Button style={{ backgroundColor: "#85BF4F", width: "220px", height: '52px', borderRadius: '4px', padding: '16px, 32px, 16px, 32px' }} href="/contact" >
                            <Typography sx={{ fontFamily: 'Goldman', color: "#F9FAFB", fontWeight: '400', fontStyle: 'normal', lineHeight: '19px', fontSize: '16px', }}>Send message</Typography>
                            <img src={ChatIcon} alt="chat-icon" style={{ marginLeft: '10px' }} />
                        </Button>
                    </Grid>
                    <Grid item xs={1}>
                        <Button style={{ backgroundColor: "#1F2937" }} sx={{ fontFamily: 'Goldman', border: "1px solid #374151", width: "193px", height: '52px', borderRadius: '4px', padding: '16px, 32px, 16px, 32px' }} href="/about">
                            <Typography sx={{ fontFamily: 'Goldman', color: "#D1D5DB", fontWeight: '400', fontStyle: 'normal', lineHeight: '19px', fontSize: '16px', }}>Learn more
                            </Typography>
                            <img src={ArrowIcon} alt="arrow-icon" style={{ marginLeft: '24px' }} />
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={3} sx={{ display: { xs: 'none', md: 'block' }}}>
                <img src={props.image} alt="Intellegent Advisor Logo" height="320px" style={{ marginLeft: '46px' }} />
            </Grid>
            <Grid
                sx={{ display: { xs: 'block', md: 'none' }, marginLeft: 'auto', marginRight: 'auto', maxWidth: '312px', }}
                container spacing={3}
            >
                <Grid item sx={{ display: 'flex', justifyContent: 'center'}}>
                    <img src={props.image} alt="Intellegent Advisor Logo" height="128px" />
                </Grid>
                <Grid item sx={{ display: 'flex', justifyContent: 'center'}}>
                    <Typography variant="h2" style={{letterSpacing: '0.04em'}} sx={{ fontFamily: 'Goldman', color: "#F9FAFB", textTransform: "uppercase", fontWeight: '700', lineHeight: '36px', fontSize: '24px', textAlign: 'center'}}>
                        Specialized in eligibility automation
                    </Typography>
                </Grid>
                <Grid item sx={{ display: 'flex', justifyContent: 'center'}}>
                    <Typography variant="h6" sx={{ color: "#E5E7EB", fontWeight: '400', fontSize: '16px', lineHeight: '24px', marginTop: '12px',  textAlign: 'center'}}>
                        Building custom solutions using <a href="https://www.oracle.com/cx/service/intelligent-advisor/" style={{ color: "#FB7908" }}>Oracle's Intelligent Advisor</a> platform.
                    </Typography>
                </Grid>
                <Grid item sx={{ display: 'flex', justifyContent: 'center'}}>
                    <Button style={{ backgroundColor: "#85BF4F", width: "220px", height: '52px', borderRadius: '4px', padding: '16px, 32px, 16px, 32px' }} href="/contact">
                        <Typography sx={{ fontFamily: 'Goldman', color: "#F9FAFB", fontWeight: '400', fontStyle: 'normal', lineHeight: '19px', fontSize: '16px',  }}>Send message</Typography>
                        <img src={ChatIcon} alt="chat-icon" style={{ marginLeft: '10px' }} />
                    </Button>
                </Grid>
                <Grid item sx={{ display: 'flex', justifyContent: 'center'}}>
                    <Button style={{ backgroundColor: "#1F2937" }} sx={{ fontFamily: 'Goldman', border: "1px solid #374151", width: "220px", height: '52px', borderRadius: '4px', padding: '16px, 32px, 16px, 32px' }} href="/about">
                        <Typography sx={{ fontFamily: 'Goldman', color: "#D1D5DB", fontWeight: '400', fontStyle: 'normal', lineHeight: '19px', fontSize: '16px', }}>Learn more
                        </Typography>
                        <img src={ArrowIcon} alt="arrow-icon" style={{ marginLeft: '24px' }} />
                    </Button>
                </Grid>
            </Grid>
        </Grid>

    );
}
