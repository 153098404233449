import { Outlet } from "react-router-dom";
import ResponsiveAppBar from "./components/app-bar";
import './App.css'



var sectionStyle = {
  width: "100%"
}

export default function App() {
  return (
    <div style={sectionStyle }>
      <ResponsiveAppBar />
      <Outlet />
      <footer 
      className="footer"
      style={{
        color: '#d1dcde',
        fontWeight: '400',
        display: 'table',
        textAlign: 'center',
        marginLeft: 'auto',
        marginRight: 'auto'
      }}>
        &#169; Copyright 2022 All Rights reserved.
      </footer>
    </div>
  );
}