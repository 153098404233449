import { render } from "react-dom";
import Box from '@mui/material/Box';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import { CssBaseline, GlobalStyles } from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import chrisBurns from './static/images/christopher-burns-messin.jpg'


import App from "./App";
import About from "./routes/about"
import Team from "./routes/team"
import Home from "./routes/home"
import Contact from "./routes/contact"
import './index.css'




import reportWebVitals from './reportWebVitals';


const theme = createTheme({
  palette: {
    primary: {
      main: "#111827"
    }
  }
});


const rootElement = document.getElementById("root");
render(

  <Box>
    <ThemeProvider theme={theme} >
      <CssBaseline />
      <GlobalStyles
      styles={{
        body: {
          position: 'relative',
          background: `linear-gradient(180deg, #111827 0%, rgba(31, 41, 55, 0.9) 100%), url(${chrisBurns})`,
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed"
        },
      }}


      />

      <BrowserRouter>

        <Routes>
          <Route path="/" element={<App />}>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="team" element={<Team />} />
            <Route path="contact" element={<Contact />} />
            <Route
              path="*"
              element={
                <main style={{ padding: "1rem" }}>
                  <p>There's nothing here!</p>
                </main>
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>

    </ThemeProvider>
  </Box>,
  rootElement
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
