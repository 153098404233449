
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TextCard from '../components/Cards/Text_Card'

export default function Team() {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: '90vh' }}
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexWrap: 'wrap',
        '& > :not(style)': {
          m: 1,
          width: { xs: 420, md: 960, lg: 1200 },
         
        },
      }}

    >
      <Paper elevation={0} style={{ backgroundColor: 'transparent', minHeight: '2em' }}>
        <TextCard
          title={<section><span>Over </span><span style={{color: '#85BF4F'}}>30 years </span><span>of health and human services experience</span></section>}
          body_two='With over thirty years of combined industry experience in the implementation of eligibility systems, we bring a unique combination enthusiasm and expertise to help our clients succeed.'
          body_three='Supported systems in California, Kansas, Ohio, Iowa, Idaho, Hawaii, Georgia, Michigan, Tennessee and federal agencies including the IRS'
        />
        <TextCard
          title={<section><span>Commitment to </span><span style={{color: '#85BF4F'}}>excellence</span></section>}
          body_two='By working with the industry&#39;s best and most experienced developers, we take pride in what we do and fully guarantee our work'
          body_three='Delivering innovative and robust solutions'
        />

      </Paper>
    </Box>
  );
}