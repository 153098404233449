import * as React from 'react';
import { NavLink } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ChatIcon from '../static/images/solid-chat-alt.svg'

import { ReactComponent as PaperAirplane } from '../static/images/paper_airplane.svg';
import saga_image from '../static/images/Sagasys/svg/Sagasys logo-horizontal-light.svg';


const theme = createTheme({
  palette: {
    primary: {
      main: "#111827"
    }
  },
  typography: { fontFamily: ["Goldman", "cursive"].join(",") }
});


const pages = [
  {
    title: 'Home',
    path: './'
  },
  {
    title: 'About',
    path: './about'
  },
  {
    title: 'Team',
    path: './team'
  }
];
// const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  // const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  // const handleOpenUserMenu = (event) => {
  //   setAnchorElUser(event.currentTarget);
  // };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  // const handleCloseUserMenu = () => {
  //   setAnchorElUser(null);
  // };

  return (
    <ThemeProvider theme={theme}>
      <AppBar position="static">
        <Container maxWidth='xl' >
          <Toolbar disableGutters>
            <Box flexGrow={1} sx={{ width: '200px', display: {xs: 'none', md: 'flex'}  }}>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ mr: 2, display: 'flex' }}
              >
                <img src={saga_image} alt="sagasys logo" style={{ height: 45, marginRight: '36px' }} />
              </Typography>
            </Box>
            <Box flexGrow={1} sx={{ width: '200px', display: {xs: 'flex', md: 'none'}  }}>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ mr: 2, display: 'flex' }}
              >
                <img src={saga_image} alt="sagasys logo" style={{ height: 30, marginRight: '36px' }} />
              </Typography>
            </Box>


            <Box sx={{ display: { xs: 'flex', md: 'none' }, }}>
              <IconButton
                size="large"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
                sx={{ display: !Boolean(anchorElNav) ? 'block' : 'none'  }}
              >
                <MenuIcon  sx={{verticalAlign: 'middle'}} />
              </IconButton>
              <IconButton
                size="large"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleCloseNavMenu}
                color="inherit"
                sx={{ display: Boolean(anchorElNav) ? 'block' : 'none' , zIndex: '9999' }}
              >
                <CloseIcon  />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                marginThreshold={0}
                PaperProps={{
                  style: {
                    background: '#111827',
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    maxHeight: '100%',
                    maxWidth: '100%',
                    inset: '0px 0px 0px 0px'
                  }
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 1
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 1
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                MenuListProps={{
                  style: {
                    height: '100%'
                  }
                }}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                <List aria-label="links" style={{ marginLeft: '12px', marginTop: '80px' }}>
                  {pages.map((page, index) => (
                    <div>
                      <ListItem style={({ isActive }) => {
                        return {

                          color: isActive ? "#85BF4F" : "#9CA3AF",
                          textDecoration: 'none',
                          marginBottom: '8px'
                        };
                      }}
                        key={`${page.path}_${index}`}
                        to={page.path}
                        component={NavLink}
                        divider
                        button
                        onClick={handleCloseNavMenu} >
                        <ListItemText key={`list_item_${page.title}_${index}`} primary={page.title} />

                      </ListItem>
                      <Divider  key={`divider${index}`} component="li" sx={{ width: '90%', height: '1px', backgroundColor: '#1F2937', marginLeft: '16px', marginBottom: '8px' }} />
                    </div>
                  ))}
                </List>
                <div key={`button_key_div`} style={{ display: 'flex', width: '100%', justifyContent: 'center', bottom: '32px', position: 'absolute' }}>
                  <Button style={{ backgroundColor: "#85BF4F", width: "220px", height: '52px', borderRadius: '4px', padding: '16px, 32px, 16px, 32px' }} >
                    <Typography sx={{ fontFamily: 'Goldman', color: "#F9FAFB", fontWeight: '400', fontStyle: 'normal', lineHeight: '19px', fontSize: '16px', }}>Send message</Typography>
                    <img src={ChatIcon} alt="chat-icon" style={{ marginLeft: '10px' }} />
                  </Button>
                </div>
              </Menu>

            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, color: "#85BF4F", alignItems: 'center', marginRight: '5em' }}>
              <Box sx={{ display: 'contents', fontFamily: 'Goldman' }} >
                {pages.map((page) => (
                  <Button
                    key={`${page.path}_desktopButton`}
                    onClick={handleCloseNavMenu}
                    sx={{ my: 2, color: 'white', display: 'block', width: '100%', textTransform: 'none', maxWidth: '52px' }}
                  >
                    <NavLink key={`${page.path}_navlink`} style={({ isActive }) => {
                      return {

                        color: isActive ? "#85BF4F" : "#9CA3AF",
                        textDecoration: 'none'
                      };
                    }}
                      to={page.path}>
                      {page.title}
                    </NavLink>

                  </Button>
                ))}
              </Box>
              <Divider
                key='final_divider'
                sx={{ width: '8px', height: '8px' }}
                style={{
                  border: "1px solid #374151",
                  boxSizing: 'border-box',
                  borderRadius: '9999px',
                  marginLeft: '48px'

                }}
                variant='center'
              />
              <Divider
               key='final_divider_two'
                sx={{ bgcolor: (theme) => '#374151', width: '518px' }}
                style={{
                  border: "none",
                  height: 1,
                  margin: 0,
                  backgroundColor: '#374151'

                }}
                variant='center'
              />
              <Button key='contact_button' sx={{ border: "1px solid #374151", height: '2.5em', width: '8em', alignItems: 'center', justifyContent: 'center', bgcolor: '#1F2937', minWidth: '10em' }} href="/contact">
                <Typography
                  key='contact_text'
                  noWrap
                  component="div"
                  color='#D1D5DB'>Contact
                </Typography>
                <PaperAirplane key='paper_plane' style={{ transform: 'rotate(90deg)', marginLeft: '.5em' }} />
              </Button>
            </Box>


            {/* Maybe someday we'll need to log in and stuff
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box> */}
          </Toolbar>
        </Container>
      </AppBar>
    </ThemeProvider>
  );
};
export default ResponsiveAppBar;
