import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function SagaCard(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.up('md'));

  const mobileClassName = !isMobile ? 'box-team' : '';

  return (
    <Card
      className={mobileClassName}
      style={{ backgroundColor: 'transparent', lineHeight: '2em', marginBottom: '2em' }} >

      <CardContent style={{ fontFamily: 'Goldman', color: "#F9FAFB", fontWeight: '700', }}>
        {props.title && props.title !== null ?
          <Typography gutterBottom variant="h3" component="div" style={{ fontFamily: 'Goldman', color: "#F9FAFB", }} sx={{ fontSize: { xs: '24px', md: '48px' }, marginBottom: { xs: '1em', md: '0' } }}>
            {props.title}
          </Typography>
          :
          ""
        }

        {props.body_two && props.body_two !== null ?
          <Typography variant="body2" color="text.secondary" style={{ fontWeight: '400', }} sx={{ fontSize: { xs: '20px', md: '24px' }, color: { xs: '#E5E7EB', md: '#E5E7EB' }, fontFamily: { xs: 'Roboto', md: 'Goldman' } }}>
            {props.body_two}
          </Typography>
          :
          ""
        }

        {props.body_three && props.body_three !== null ?
          <Typography variant="body2" color="text.secondary" style={{ fontFamily: 'Goldman', textTransform: "uppercase", color: "#FB7908", fontWeight: '400', fontSize: '20px', lineHeight: '0em' }} sx={{ display: { xs: 'none', md: 'contents' }, margin: { xs: '1em', md: '5em' } }}>
            {props.body_three}
          </Typography>
          :
          ""
        }
      </CardContent>

    </Card>
  );
}