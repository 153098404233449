import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import ListItemText from '@mui/material/ListItemText';
import useMediaQuery from '@mui/material/useMediaQuery';




export default function SagaCard(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.up('md'));

  const mobileClassName = !isMobile ? 'gradient-box-about' : '';

  return (
    <Card 
    className={mobileClassName}
      sx={{ flexGrow: 1, 
        display: { xs: 'block', md: 'flex' }, 
        margin: { xs: '20px', md: '60px' }, 
        maxWidth: { xs: '400px', md: '900px', lg: '1000px' } }} 
      style={{ backgroundColor: 'transparent', 
      width: { xs: '220px', md: '960px' }, }}>


      {props.image && props.image !== null ?
        <CardMedia
          component="img"
          height="200"
          sx={{ display: { xs: 'none', md: 'flex' }, flexDirection: 'column' }}
          image={props.image}
          alt={props.alt_text}
        />
        :
        ""
      }


      {props.icon && props.icon !== null ?

        <props.icon sx={{ fontSize: '200px', display: { xs: 'none', md: 'flex' }, ...props.color } } />

        :
        ""
      }

      {props.icon && props.icon !== null ?
        <props.icon sx={{ fontSize: '100px', margin: 'auto', marginTop: '1rem', display: { xs: 'flex', md: 'none' }, ...props.color }} />
        :
        ""
      }

      <CardContent style={{ fontFamily: 'Goldman', color: "#F9FAFB", fontWeight: '700', }}>
        {props.title && props.title !== null ?
          <Typography gutterBottom variant="h3" component="div" style={{ fontFamily: 'Goldman', color: "#F9FAFB", }} sx={{ fontSize: { xs: '20px', md: '48px' }, textAlign: { xs: 'left', md: 'center' } }}>
            {props.title}
          </Typography>
          :
          ""
        }

        {props.body_two && props.body_two !== null ?
          <Typography variant="body2" color="text.secondary" style={{ fontWeight: '400', }} sx={{ fontFamily: { xs: 'Roboto', md: 'Goldman' }, fontSize: { xs: '16px', md: '24px' }, color: { xs: '#E5E7EB', md: '#E5E7EB' }, textAlign: { xs: 'left', md: 'center' } }}>
            {props.body_two}
          </Typography>
          :
          ""
        }

        {props.body_three && props.body_three !== null ?
          <Typography variant="body5" color="text.secondary" style={{ fontWeight: '400', lineHeight: '0em' }} sx={{ fontFamily: { xs: 'Roboto', md: 'Goldman' }, color: { xs: '#E5E7EB', md: '#FB7908' }, fontSize: { xs: '16px', md: '20px' }, textAlign: { xs: 'left', md: 'center' }, textTransform: { xs: 'none', md: 'uppercase' } }}>
            {!isMobile ?
              <List>{props.body_three.split(', ').map((el, i) =>
                <ListItem sx={{ display: 'list-item', listStyleType: 'disc', marginLeft: '1em', padding: '0' }} key={i}>
                  <ListItemText>{el}</ListItemText>
                </ListItem>)}
              </List> : props.body_three}
          </Typography>
          :
          ""
        }

      </CardContent>

    </Card>
  );
}