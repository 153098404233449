
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';


export default function Team() {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: '90vh' }}
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexWrap: 'wrap',
        '& > :not(style)': {
          m: 1,
          width: { xs: 420, md: 960, lg: 1200 },
        },
      }}

    >
      <Paper elevation={0} style={{ backgroundColor: 'transparent', lineHeight: '2em', }}>
        <Typography gutterBottom variant="h3" component="div" style={{ fontFamily: 'Goldman', color: "#F9FAFB", }} sx={{ fontSize: { xs: '24px', md: '48px' } }}>
          Please reach out if you have any questions or would like to discuss opportunities
        </Typography>
        <Typography gutterBottom variant="h3" component="div" style={{ fontFamily: 'Goldman', color: "#FB7908", }} sx={{ fontSize: { xs: '24px', md: '48px' } }} href="mailto:info@sagasys.tech">
          info@sagasys.tech
        </Typography>
      </Paper>
    </Box>
  );
}