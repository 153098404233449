import * as React from 'react';
import SagaCard from '../components/Cards/card'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import HandymanIcon from '@mui/icons-material/Handyman';
import ArchitectureIcon from '@mui/icons-material/Architecture';
import { green } from '@mui/material/colors';


const Item = styled(Grid)(({ theme }) => ({

  ...theme.typography.body2,

  padding: theme.spacing(1),
  textAlign: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  maxWidth: '960px',
  color: theme.palette.text.secondary,
}));


export default function About() {
  return (

    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{
        margin: { sm: '0em', md: '15em' },

      }}
    >
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '90vh', }} >


        <Item item >
          <SagaCard title="Eligibility Systems" body_two="Design, build and maintenance of eligibility determinations" body_three="Medicaid, SNAP, TANF, Foster Care, Energy Assistance Programs, Child Care, COVID-19 Related Programs" icon={ArchitectureIcon} color={{ color: green[500] }} />
        </Item>
        <Item item>
          <SagaCard title="Productivity Enhancing Tools" body_two="Custom tools and integration to support the system development life cycle" body_three="Automated Deployments, deployment management, regression testing, reporting" icon={HandymanIcon} color={{ color: '#FB7908' }} />
        </Item>
        <Item item>
          <SagaCard title="Custom Cloud Management Solutions" body_two="Manage multiple cloud instances from one location" body_three="Regression Testing, Enhanced reporting capabilities" icon={CloudQueueIcon} color={{ color: green[500] }} />
        </Item>
        <Item item>
          <SagaCard title="Continuous Integration and Continuous Deployment" body_two="Automated build and deployment of OPM rulebases" body_three="Seamless integration of the build and deploy process" icon={CloudSyncIcon} color={{ color: '#FB7908' }} />
        </Item>
      </Grid>
    </Box>


  );
}